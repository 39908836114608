//
// Billing Summary
// --------------------------------------------------

.billing-summary {
  margin-bottom: 20px;

  .heading {
    color: @text-color-light;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
    margin-bottom: 3px;

    @media (min-width: @screen-sm-min) {
      margin-bottom: 5px;
    }
  }

  .currency {
    color: @text-color;
    font-size: @font-size-h3;
    font-weight: @font-weight-regular;
    margin-bottom: 10px;

    @media (min-width: @screen-sm-min) {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
}
