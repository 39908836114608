//
// Slide Well
// --------------------------------------------------

.slide-well {
  width: 100%;
  background: #F4F5F6;
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
  box-shadow: inset 0 0 5px 0 fade(#000, 10);
  padding: 10px 0;
}

@media (min-width: @screen-sm-min) {
  .slide-well {
  }
}
