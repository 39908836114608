// make them behave like buttons and line up
div.dropdown {
  display: inline-block;
}

.dropdown {
  position: relative;

  &.open {
    .btn {
      background-color: @gray-75;
      box-shadow: inset 0 1px 1px 0 fade(#000, 7);
    }
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0; // override default ul
    list-style: none;
    font-size: @font-size-regular;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: #FFF;
    border: 1px solid @gray-150;
    border-radius: @border-radius-small;
    background-clip: padding-box;
    box-shadow: 0 1px 4px fade(#000, 8);

    &.dropdown-menu-right {
      left: auto; // Reset the default from `.dropdown-menu`
      right: 0;
    }

    &.dropdown-menu-left {
      left: 0;
      right: auto;
    }

    // Dividers (basically an hr) within the dropdown
    .divider {
      .nav-divider(@gray-75);
    }

    > li a:hover,
    > li button:hover {
      background: @gray-75;
    }

    // Links, buttons and text within the dropdown menu
    > li {
      > .dropdown-text {
        color: @gray-200;
        margin: 0;
      }
      > a,
      > button,
      > .dropdown-text {
        border: none;
        border-radius: @border-radius-small;
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        text-decoration: none;
      }

      > button {
        background-color: transparent;
        background-image: none;
        width: 100%;
        text-align: left;
      }
    }

    > li.active {
      > a {
        font-weight: @font-weight-bold;
      }
    }
  }
}

.dropdown-dismiss {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
