//
// Spacing utilities
//

// Vertically center inside a div with a specified height
.v-center {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-xxs {
  padding: @space-xxs !important;
}

.p-xs {
  padding: @space-xs !important;
}

.p-sm {
  padding: @space-sm !important;
}

.p-md {
  padding: @space-md !important;
}

.p-lg {
  padding: @space-lg !important;
}

.p-xl {
  padding: @space-xl !important;
}

.m-xxs {
  margin: @space-xxs !important;
}

.m-xs {
  margin: @space-xs !important;
}

.m-sm {
  margin: @space-sm !important;
}

.m-md {
  margin: @space-md !important;
}

.m-lg {
  margin: @space-lg !important;
}

.m-xl {
  margin: @space-xl !important;
}

// Vertical Spacing

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: @space-xxs !important;
}

.m-t-xs {
  margin-top: @space-xs !important;
}

.m-t-sm {
  margin-top: @space-sm !important;
}

.m-t-md {
  margin-top: @space-md !important;
}

.m-t-lg {
  margin-top: @space-lg !important;
}

.m-t-xl {
  margin-top: @space-xl !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-xxs {
  margin-bottom: @space-xxs !important;
}

.m-b-xs {
  margin-bottom: @space-xs !important;
}

.m-b-sm {
  margin-bottom: @space-sm !important;
}

.m-b-md {
  margin-bottom: @space-md !important;
}

.m-b-lg {
  margin-bottom: @space-lg !important;
}

.m-b-xl {
  margin-bottom: @space-xl !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-xxs {
  padding-top: @space-xxs !important;
}

.p-t-xs {
  padding-top: @space-xs !important;
}

.p-t-sm {
  padding-top: @space-sm !important;
}

.p-t-md {
  padding-top: @space-md !important;
}

.p-t-lg {
  padding-top: @space-lg !important;
}

.p-t-xl {
  padding-top: @space-xl !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-xxs {
  padding-bottom: @space-xxs !important;
}

.p-b-xs {
  padding-bottom: @space-xs !important;
}

.p-b-sm {
  padding-bottom: @space-sm !important;
}

.p-b-md {
  padding-bottom: @space-md !important;
}

.p-b-lg {
  padding-bottom: @space-lg !important;
}

.p-b-xl {
  padding-bottom: @space-xl !important;
}

// Horizontal Spacing

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-xxs {
  margin-left: @space-xxs !important;
}

.m-l-xs {
  margin-left: @space-xs !important;
}

.m-l-sm {
  margin-left: @space-sm !important;
}

.m-l-md {
  margin-left: @space-md !important;
}

.m-l-lg {
  margin-left: @space-lg !important;
}

.m-l-xl {
  margin-left: @space-xl !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-xxs {
  margin-right: @space-xxs !important;
}

.m-r-xs {
  margin-right: @space-xs !important;
}

.m-r-sm {
  margin-right: @space-sm !important;
}

.m-r-md {
  margin-right: @space-md !important;
}

.m-r-lg {
  margin-right: @space-lg !important;
}

.m-r-xl {
  margin-right: @space-xl !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-xxs {
  padding-left: @space-xxs !important;
}

.p-l-xs {
  padding-left: @space-xs !important;
}

.p-l-sm {
  padding-left: @space-sm !important;
}

.p-l-md {
  padding-left: @space-md !important;
}

.p-l-lg {
  padding-left: @space-lg !important;
}

.p-l-xl {
  padding-left: @space-xl !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-r-xxs {
  padding-right: @space-xxs !important;
}

.p-r-xs {
  padding-right: @space-xs !important;
}

.p-r-sm {
  padding-right: @space-sm !important;
}

.p-r-md {
  padding-right: @space-md !important;
}

.p-r-lg {
  padding-right: @space-lg !important;
}

.p-r-xl {
  padding-right: @space-xl !important;
}
