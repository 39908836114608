//
// Visibility Utilities
//

// Toggling content
.hide {
  display: none;
}

.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: @screen-xs-max) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: @screen-xs) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: @screen-sm-max) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: @screen-sm) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: @screen-md-max) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: @screen-md) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: @screen-lg-max) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: @screen-lg) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}
