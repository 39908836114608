.new-list-view-header {
  background-color: @new-list-view-header-bg;
  box-sizing: border-box;
  padding: 6px 0;
  text-transform: uppercase;
  .clearfix();

  h3 {
    padding: 0 15px;
    font-size: 12px;
    display: block;
    float: left;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0;

    &.bottom-align {
      padding-top: 36px;
    }

    .btn {
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: none;
      font-weight: @font-weight-regular;
      letter-spacing: 1px;

      &:active,
      &:focus,
      &:hover {
        outline: none;
      }
    }
  }

  .heading {
    letter-spacing: 1px;
    font-weight: @font-weight-regular;
    text-align: right;
  }

  .value {
    font-size: 40px;
    line-height: 30px;
    text-align: right;
  }

  &.totals {
    background: none;
    border-bottom: 2px solid @border-color;
  }

  &.new-list-view-status {
    padding-left: 15px;
  }
}

.new-list-view {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {

    > a {
      text-decoration: none;
    }

    .list-view-item,
    .list-view-link-item,
    .list-view-editable-item,
    .list-view-read-only-item {
      border-bottom: 1px solid @gray-75;
      background-color: @list-view-item-bg;
      padding: 10px 0;
      transition: e('background .05s ease-in');
      .clearfix();

      &.no-hover {
        cursor: default;
        background: none;

        &:hover {
          background: none;
        }
      }

      &:hover {
        background-color: @gray-50;

        .new-list-view-actions {
          opacity: 1;
        }
      }

      // colored list view item backgrounds
      &.list-view-item-success {
        background-color: @state-success-bg;
        border-bottom-color: @state-success-border;
      }

      &.list-view-item-info {
        background-color: @state-info-bg;
        border-bottom-color: @state-info-border;
      }

      &.list-view-item-warning {
        background-color: @state-warning-bg;
        border-bottom-color: @state-warning-border;
      }

      &.list-view-item-danger {
        background-color: @state-danger-bg;
        border-bottom-color: @state-danger-border;
      }

      .new-list-view-actions {
        text-align: right;
        opacity: 0;
        transition: e('opacity .1s linear');
      }
    }

    .list-view-item:hover {
      background-color: inherit;
    }

    .list-view-link-item:hover {
      background: @gray-50;
    }

    .list-view-editable-item {
      cursor: pointer;

      &:hover {
        background: @gray-50;

        &.list-view-item-success {
          background-color: darken(@state-success-bg, 10%);
        }

        &.list-view-item-info {
          background-color: darken(@state-info-bg, 5%);
        }

        &.list-view-item-warning {
          background-color: darken(@state-warning-bg, 10%);
        }

        &.list-view-item-danger {
          background-color: darken(@state-danger-bg, 5%);
        }
      }
    }
    .list-view-read-only-item {
      background-color: @gray-50;
    }
  }

  // handle nesting out to 12 levels after 6 levels the padding gets less
  // at 12 they are all the same.
  li {

    // 0
    li {
      .col-indent {
        padding-left: 30px; // 1
      }

      li {
        .col-indent {
          padding-left: 45px; // 2
        }

        li {
          .col-indent {
            padding-left: 60px; // 3
          }

          li {
            .col-indent {
              padding-left: 75px; // 4
            }

            li {
              .col-indent {
                padding-left: 90px; // 5
              }

              li {
                .col-indent {
                  padding-left: 105px; // 6
                }

                li {
                  .col-indent {
                    padding-left: 110px; // 7
                  }

                  li {
                    .col-indent {
                      padding-left: 115px; // 8
                    }

                    li {
                      .col-indent {
                        padding-left: 120px; // 9
                      }

                      li {
                        .col-indent {
                          padding-left: 125px; // 10
                        }

                        li {
                          .col-indent {
                            padding-left: 130px; // 11
                          }

                          li {
                            .col-indent {
                              padding-left: 135px; // 12
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .validationMessage {
    font-size: 13px;
    display: inline-block;

    ul {
      margin: 0 0 10px 25px;

      li {
        border-bottom: none;
        padding: initial;
        line-height: normal;
        cursor: default;
        list-style-type: disc;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.new-list-view-status {

  > li {

    .list-view-editable-item, .list-view-link-item, .list-view-item, .list-view-read-only-item {
      margin-left: 15px;
    }

    box-shadow: inset 0 -1px 0 0 #FFF, inset 15px 0 @border-color;
    &.status-warning {
      box-shadow: inset 0 -1px 0 0 #FFF, inset 15px 0 @brand-warning;
    }
    &.status-danger {
      box-shadow: inset 0 -1px 0 0 #FFF, inset 15px 0 @brand-danger;
    }
    &.status-success {
      box-shadow: inset 0 -1px 0 0 #FFF, inset 15px 0 @brand-success;
    }

    &.empty-list-view-item,
    &.loading-list-view-items {
      box-shadow: none;
    }
  }
}

.new-list-view-no-header {
  > li:first-child {
    border-top: 1px solid @border-color;
  }
}

.empty-list-view-item {
  border-bottom: 1px solid @border-color;
  padding: 10px 15px;
  text-align: center;
  background-color: #FFF;

  .btn-link {
    padding-right: 0;
    padding-left: 0;
  }

  &.feature {
    border-left: 1px solid @border-color;
    border-right: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    padding: 10px 20px;

    > svg.cyclops-icon {
      margin-top: 20px;
    }

    > svg.cyclops-icon + h3 {
      margin-top: 10px;
    }

    > .lead {
      margin-left: auto;
      margin-right: auto;
      font-size: @font-size-regular;
      max-width: 600px;

      @media (min-width: @screen-sm-min) {
        font-size: @font-size-large;
      }
    }
  }
}

.loading-list-view-items,
.error-list-view-items {
  border-bottom: 1px solid @border-color;
  padding: 10px 15px;
  text-align: center;
  background-color: #FFF;

  .cyclops-icon {
    height: 1.2em;
    width: 1.2em;
  }
}

.list-view-item-form {
  background: @gray-50;
  border: 1px solid @gray-100;
  box-shadow: inset 0 0 2px 0 fade(#000, 10);
  padding: 10px 20px;

  .validation-summary {
    color: #B94A48;
    margin: 0 0 0 13px;
    list-style-type: disc;
    margin-bottom: 15px;

    li {
      border: none;
      padding: 0;
      line-height: 25px;
    }
  }

  [title],
  [data-original-title] {
    text-decoration: underline;
  }

  // Knockout Validation will put and empty title which would cause
  // underlines when there was no hover text
  [title=""],
  [data-original-title=""],
  input[title],
  select[title],
  textarea[title] {
    text-decoration: none;
  }

  [data-password-strength='0'],
  [data-password-strength='1'],
  [data-password-strength='2'] {
    color: @brand-danger;
  }

  [data-password-strength='3'] {
    color: @text-color;
  }

  [data-password-strength='4'] {
    color: green;
  }

  ol {
    margin: 0;
    list-style-type: none;
    background-color: #FFF;

    &.custom-input-control {
      max-height: 200px;
      overflow: auto;
    }

    li {
      padding: 0;
      border: none;

      &:hover {
        background: #FFF;
      }

      .item-content {
        border-bottom: 1px solid @gray-100;
        padding-left: 20px;
        cursor: pointer;
      }

      .item-hover {
        &:hover {
          background-color: @gray-75;
        }
      }

      .item-hover.item-selected {
        background: #313336;
        color: #FFF;
      }

      li {
        .item-content {
          padding-left: 40px;
        }

        li {
          .item-content {
            padding-left: 60px;
          }

          li {
            .item-content {
              padding-left: 80px;
            }

            li {
              .item-content {
                padding-left: 100px;
              }

              li {
                .item-content {
                  padding-left: 120px;
                }

                li {
                  .item-content {
                    padding-left: 140px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  label.error {
    color: @text-color-error;
  }

  select.error,
  input.error {
    color: @text-color;

    &:focus {
      box-shadow: none;
    }
  }
}

/* columns within a listview */
.col {
  display: block;
  float: left;
  padding: 0 15px;
  vertical-align: top;
  box-sizing: border-box;
  min-height: 1px;
  /*hack so that empty divs won't collapse on itself*/
  word-wrap: break-word;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 35%;
}

.col-40 {
  width: 40%;
}

.col-45 {
  width: 45%;
}

.col-50 {
  width: 50%;
}

.col-55 {
  width: 55%;
}

.col-60 {
  width: 60%;
}

.col-65 {
  width: 65%;
}

.col-70 {
  width: 70%;
}

.col-75 {
  width: 75%;
}

.col-80 {
  width: 80%;
}

.col-85 {
  width: 85%;
}

.col-90 {
  width: 90%;
}

.col-95 {
  width: 95%;
}

.col-100 {
  width: 100%;
}

.new-list-view-toolbar {
  line-height: 30px;
  margin-bottom: floor(@line-height-computed / 2);

  .new-list-view-toolbar-right-collapsed();

  .btn,
  .dropdown {
    vertical-align: top;
  }

  > .btn, > .dropdown {
    margin-bottom: 5px;
  }

  @media (min-width: @grid-float-breakpoint) {

    .new-list-view-toolbar-right-expanded();

    >.btn, > .dropdown {
      margin-bottom: 0;
    }

  }
}

.new-list-view-toolbar--border {
  border: 2px solid @gray-100;
  background-color: @gray-50;
  padding: 15px 15px;
}

.new-list-view-responsive {
  .col-header {
    display: none;
  }
  .new-list-view-responsive-expanded();

  @media (max-width: 0) {
    .new-list-view-responsive-collapsed();
    .new-list-view-toolbar-right-collapsed();
  }

  &.new-list-view-responsive-sm {
    .new-list-view-responsive-expanded();
    .new-list-view-toolbar-right-expanded();

    @media (max-width: @screen-xs-max) {
      .new-list-view-responsive-collapsed();
      .new-list-view-toolbar-right-collapsed();
    }
  }

  &.new-list-view-responsive-md {
    .new-list-view-responsive-expanded();
    .new-list-view-toolbar-right-expanded();

    @media (max-width: @screen-sm-max) {
      .new-list-view-responsive-collapsed();
      .new-list-view-toolbar-right-collapsed();
    }
  }

  &.new-list-view-responsive-lg {
    .new-list-view-responsive-expanded();
    .new-list-view-toolbar-right-expanded();

    @media (max-width: @screen-md-max) {
      .new-list-view-responsive-collapsed();
      .new-list-view-toolbar-right-collapsed();
    }
  }
}

// The classes bellow are not meant to be used...
// they are meant to be included in other classes
// go go less!
.new-list-view-responsive-collapsed {

  .new-list-view-toolbar-right-collapsed();

  > ul {
    border-top: solid 1px @border-color;
  }

  > li {
    padding: 15px 0;
  }

  li {

    // 0
    li {
      .list-view-item,
      .list-view-link-item,
      .list-view-editable-item,
      .list-view-read-only-item {

        // 1
        padding-left: 15px;
      }

      .col-indent {
        padding-left: 15px;
      }

      li {
        .list-view-item,
        .list-view-link-item,
        .list-view-editable-item,
        .list-view-read-only-item{

          // 2
          padding-left: 30px;
        }

        .col-indent {
          padding-left: 15px;
        }

        li {
          .list-view-item,
          .list-view-link-item,
          .list-view-editable-item,
          .list-view-read-only-item {

            // 3
            padding-left: 45px;
          }

          .col-indent {
            padding-left: 15px;
          }

          li {
            .list-view-item,
            .list-view-link-item,
            .list-view-editable-item,
            .list-view-read-only-item {

              // 4
              padding-left: 60px;
            }

            .col-indent {
              padding-left: 15px;
            }

            li {
              .list-view-item,
              .list-view-link-item,
              .list-view-editable-item,
              .list-view-read-only-item {

                // 5
                padding-left: 75px;
              }

              .col-indent {
                padding-left: 15px;
              }

              li {
                .list-view-item,
                .list-view-link-item,
                .list-view-editable-item,
                .list-view-read-only-item {

                  // 6
                  padding-left: 90px;
                }

                .col-indent {
                  padding-left: 15px;
                }

                li {
                  .list-view-item,
                  .list-view-link-item,
                  .list-view-editable-item,
                  .list-view-read-only-item {

                    // 7
                    padding-left: 105px;
                  }

                  .col-indent:before {
                    padding-left: 15px;
                  }

                  li {
                    .list-view-item,
                    .list-view-link-item,
                    .list-view-editable-item,
                    .list-view-read-only-item {

                      // 8
                      padding-left: 120px;
                    }

                    .col-indent:before {
                      padding-left: 15px;
                    }

                    li {
                      .list-view-item,
                      .list-view-link-item,
                      .list-view-editable-item,
                      .list-view-read-only-item {

                        // 9
                        padding-left: 135px;
                      }

                      .col-indent:before {
                        padding-left: 15px;
                      }

                      li {
                        .list-view-item,
                        .list-view-link-item,
                        .list-view-editable-item,
                        .list-view-read-only-item {

                          // 10
                          padding-left: 150px;
                        }

                        .col-indent:before {
                          padding-left: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .col {
    float: none;
    margin-bottom: 15px;
    text-align: initial !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .col-10,
  .col-15,
  .col-20,
  .col-25,
  .col-30,
  .col-35,
  .col-40,
  .col-45,
  .col-50,
  .col-55,
  .col-60,
  .col-65,
  .col-70,
  .col-75,
  .col-80,
  .col-85,
  .col-90,
  .col-95,
  .col-100 {
    width: auto;
  }

  .col-header {
    display: initial;
  }

  span.col-header {
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: @font-weight-bold;
    cursor: default;
  }

  .new-list-view-header {
    display: none;
  }
}

.new-list-view-responsive-expanded {
  .col {
    display: block;
    float: left;
    vertical-align: top;
    box-sizing: border-box;
    min-height: 1px;
    /*hack so that empty divs won't collapse on itself*/
    word-wrap: break-word;
  }

  .col-10 {
    width: 10%;
  }

  .col-15 {
    width: 15%;
  }

  .col-20 {
    width: 20%;
  }

  .col-25 {
    width: 25%;
  }

  .col-30 {
    width: 30%;
  }

  .col-35 {
    width: 35%;
  }

  .col-40 {
    width: 40%;
  }

  .col-45 {
    width: 45%;
  }

  .col-50 {
    width: 50%;
  }

  .col-55 {
    width: 55%;
  }

  .col-60 {
    width: 60%;
  }

  .col-65 {
    width: 65%;
  }

  .col-70 {
    width: 70%;
  }

  .col-75 {
    width: 75%;
  }

  .col-80 {
    width: 80%;
  }

  .col-85 {
    width: 85%;
  }

  .col-90 {
    width: 90%;
  }

  .col-95 {
    width: 95%;
  }

  .col-100 {
    width: 100%;
  }
}

.new-list-view-toolbar-right-expanded {
  .new-list-view-toolbar-right {
    float:right;
    margin-left: 20px;
    margin-top: 0;

    > .btn, > .dropdown {
      margin-bottom: 0;
    }
  }
}

.new-list-view-toolbar-right-collapsed {
  .new-list-view-toolbar-right {
    float: none;
    margin-left: 0;

    > .btn, > .dropdown {
      margin-bottom: 5px;
    }
  }
}
