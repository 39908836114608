table {
  th, td {
    text-align: left;
    vertical-align: top;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;
  border-collapse: collapse;
  border-spacing: 0;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: top;
        border-top: 1px solid @table-border-color;
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    background-color: @table-header-bg;
    color: @gray-400;
    border: 0;
  }
   > tbody > tr {
      background-color: #FFF;
      > td {
        border-bottom: 1px solid @gray-75;
      }
      &:hover{
        background-color: @gray-25;
      }
   }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: #FFF;
  }
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  display: block;
  width: 100%;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  overflow-x: auto;
}
