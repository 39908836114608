.password-strength {
  margin: 0;
  padding-top: 10px;
  cursor: default;
}

.strength {
  &.strong {
    color: @brand-success;
  }

  &.good {
    color: @text-color;
  }

  &.weak {
    color: @state-danger-text;
  }
}
