/*
	Hover Effects
*/

/* Performant shadow on hover from http://tobiasahlin.com/blog/how-to-animate-box-shadow/ */
.hover-shadow:after {
	content: "";
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	box-shadow: 0 0px 8px 2px fade(#000, 20);
	opacity: 0;
	transition: opacity .2s ease-in-out;
}
.hover-shadow:hover:after,
.hover-shadow:focus:after {
	opacity: 1;
}

.hover-underline:hover,
.hover-underline:focus {
	text-decoration: underline;
}


.hover-dim {
	opacity: 1;

	&:hover,
	&:focus {
		opacity: .6;
	}

	&:active {
		opacity: .8;
	}
}

.hover-pointer:hover {
	cursor: pointer;
}
