// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: @font-weight-bold;
  color: @heading-text-color;
  line-height: @headings-line-height;

  a {
    color: @heading-text-color;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-top: @line-height-computed;
  margin-bottom: (@line-height-computed / 2);
  letter-spacing: 1px;

  small {
    font-weight: @font-weight-regular;
    font-size: 65%;
    color: @headings-small-color;

    a {
      color: @headings-small-color;
    }
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: (@line-height-computed / 2);
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-weight: @font-weight-regular;
    font-size: 75%;

    a {
      color: @headings-small-color;
    }
  }
}

h1,
.h1 {
  font-size: ceil((@font-size-h1 * .65));
  margin-bottom: 10px;

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h1;
  }
}

h2,
.h2 {
  font-size: ceil((@font-size-h2 * .65));
  margin-bottom: 10px;

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h2;
  }
}

h3,
.h3 {
  font-size: ceil((@font-size-h3 * .65));
  margin-bottom: 10px;

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h3;
  }
}

h4,
.h4 {
  font-size: @font-size-h4;
}

h5,
.h5 {
  font-size: @font-size-h5;
}

h6,
.h6 {
  font-size: @font-size-h6;
}

.content-header {
  border-bottom: 2px solid @border-color;
  margin-bottom: 15px;
  padding-bottom: (@line-height-computed / 2);
  letter-spacing: 1px;

  .lead {
    color: @text-color-lighter;
    margin-bottom: 0;

    @media (min-width: @screen-sm-min) {
      margin-top: -7px;
    }
  }

  &.condensed {
    h3,
    h4,
    h5,
    h6,
    .h3,
    .h4,
    .h5,
    .h6 {
      padding: 0 7px;
    }
  }

  &.with-input {
    height: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  &.content-header {
    letter-spacing: 1px;
  }
}

// Body text
// -------------------------

body {
  font-weight: @font-weight-regular;
  font-family: @font-family;
  font-size: @font-size-regular;
  color: @text-color;
  line-height: @line-height-computed;
  text-rendering: optimizeLegibility;
}

a {
  color: @link-color;

  &.underline {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 (@line-height-computed); // 20px
}

.lead {
  margin-bottom: @line-height-computed;
  font-size: floor((@font-size-regular * 1.15));
  font-weight: @font-weight-regular;
  line-height: 1.4;

  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-regular * 1.5);
  }
}

// Large Type Feature
// -------------------------
.large-type-feature {
  margin-top: 50px;
  h1 {
    font-size: 40px;
    color: @gray-400;
    font-weight: @font-weight-bold;
    margin-bottom: 0;
  }
  .lead {
    margin-bottom: @line-height-computed;
  }

  @media (min-width: @screen-sm-min) {
    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: @screen-md-min) {
    h1 {
      font-size: 80px;
    }
  }

  @media (min-width: @screen-lg-min) {
    h1 {
      font-size: 100px;
    }
  }

  @media (min-width: @screen-xl-min) {
    h1 {
      font-size: 100px;
    }
  }

}
