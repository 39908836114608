// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
	margin-top: 0;
	margin-bottom: @line-height-computed;
	ul,
	ol {
		margin-bottom: 0;
	}
}

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
	padding-left: 0;
	list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
	.list-unstyled();
	margin-left: -5px;

	> li {
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
	}
}

// comma separated list
.list-comma {
	.list-unstyled();
  li {
    display: inline;

    &:after {
      content: ", ";
    }

    &:last-child:after {
      content: "";
    }
  }
}

// Description Lists
dl {
	margin-top: 0; // Remove browser default
	margin-bottom: @line-height-computed;
}
dt,
dd {
	line-height: @line-height-base;
}
dt {
	font-weight: @font-weight-bold;
}
dd {
	margin-left: 0; // Undo browser default
	margin-bottom: @line-height-computed / 2;
}

.list-info {
	.list-unstyled();

	> li {
		border-bottom: 1px solid @border-color;
		padding: 12px 0;
		line-height: 20px;

		.info-label {
			display: block;
			font-size: @font-size-small;
			width: 100%;
			text-transform: uppercase;
			vertical-align: top;
			letter-spacing: 1px;
		}

		.info-value {
			display: inline-block;
			width: 100%;
		}

		@media (min-width: @screen-md-min) {

			.info-label {
				display: inline-block;
				width: 35%;
				margin-right: 5%
			}

			.info-value {
				width: 55%;
			}
		}
	}
}
