// checkbox toggle
.toggle {
  position: relative;
  width: 70px;
  height: 34px;
  user-select: none;

  input {
    display: none;
  }

  input:checked + .bug-fix {
    .text {
      margin-left: 0;
      background-color: @brand-primary;
      border: 1px solid @brand-primary;
      color: #FFF;
    }

    .handle {
      right: 0;
    }
  }

  label {
    overflow: hidden;
    cursor: pointer;
    margin: 0 0 -12px 0;
    width: 70px;
    height: 34px;
  }

  .text {
    width: 70px;
    height: 34px;
    padding-top: 5px;
    background-color: @gray-200;
    border: 1px solid @gray-200;
    color: #FFF;
    border-radius: @border-radius-base;
    transition: background 0.3s ease-in, border 0.3s ease-in;
  }

  .affirmative,
  .negative {
    float: left;
    width: 34px;
    padding: 0;
    font-size: @font-size-regular;
    font-weight: @font-weight-regular;
    text-align: center;
  }

  .negative {
    left: 34px;
  }

  .handle {
    display: block;
    width: 35px;
    background: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    border-radius: @border-radius-small;
    border: 1px solid @gray-200;
    transition: all 0.3s ease-in 0s;
  }

  &.disabled {
    cursor: not-allowed;

    label {
      cursor: not-allowed;
    }

    .handle,
    .text .affirmative,
    .text .negative {
      color: @gray-175;
      background-color: @gray-75;
    }

    input + .bug-fix {
      .text {
        color: @gray-175;
        background-color: @gray-75;
        border-color: @gray-200;
      }
    }
  }
}
