#services-menu-container {
    position: relative;

    &.hidden {
        display: none;
    }

    #services-menu {
        position: absolute;
        z-index: @zindex-services-menu;
        width: 100%;
        top: 0;
    }
}

#services-menu-fade {
    opacity:0.5;
    background-color:black;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index: @zindex-services-menu - 1;

    &.hidden {
        display: none;
    }
}

#navbar-services {
    height: @navbar-height;
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    padding-left: 11px;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      top: 15px;
      bottom: 15px;
      left: 0px;
      background-color: @gray-75;
    }

    .navbar-services-menu {
        display: flex;
        flex-direction: column;
        height: 45px;
        text-decoration: none;
        border-radius: 3px;
        padding: 3px 8px 7px 10px;
        align-self: center;
        border: 1px solid transparent;

        &:not(.open):hover {
          background: #FFF;
          border: 1px solid @gray-100;
          box-shadow: 0 1px 2px fade(#000, 7);
        }

        &.open {
            background: @gray-75;
            box-shadow: inset 0 1px 2px fade(#000, 10);

            .navbar-services-menu__select-icon {
                transform: rotate(180deg);
                use {
                  fill: @gray-500;
                }
            }
        }

        &__title {
            font-size: 11px;
            color: @gray-200;

             &.no-service {
                 display: none;
            }
        }

        &__detail {
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.no-service {
                 height: 100%;
                 padding-top: 3px;
            }
        }

        &__select-icon {
            transition: all linear 150ms;
            margin-left: 3px;
        }

        &__current_service {
            color: @gray-500;
            font-size: 16px;
            font-weight: 600;
        }
    }
}
