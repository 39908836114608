//
// Action Toolbar
// --------------------------------------------------

.account-switcher-open {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

@media (min-width: @screen-xs-min) {
  .account-switcher-open {
    position: relative;
  }
}

account-switcher {
  display: block;
  height: 40px;
  background-color: @account-switcher-bg;
  z-index: @zindex-account-switcher;
  position: relative;

  button.btn-link {
    color: @gray-400;
    font-size: @font-size-small;
    text-decoration: none;
    padding-left: 0;

    &:hover {
      color: #FFF;
      outline: none;
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
    }

    @media(min-width: @grid-float-breakpoint) {
      padding-left: 10px;
    }
  }

  .header {
    display: block;
    z-index: @zindex-account-switcher;
    height: 40px;
    background-color: @account-switcher-header-bg;
    color: @account-switcher-text;
    font-weight: @font-weight-regular;
    height: 40px;
    padding: 5px 10px;
    z-index: @zindex-account-switcher;
    position: relative;
    box-shadow: -1px 1px @gray-500;

    .toggle-btn {
      padding: 0;

      .cyclops-icon use {
        transition: e('fill .05s ease-in');
        fill: @gray-200;
      }

      &:hover {
        .cyclops-icon use {
          fill: #FFF;
        }
      }
    }

    .current {
      color: @gray-200;
      font-weight: @font-weight-regular;
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      margin-right: 5px;
      transition: e('color .05s ease-in');

      &:hover {
        color: #FFF;
      }
    }
  }

  .take-over {
    background-color: @account-switcher-bg;
    overflow: hidden;
    position: fixed;
    top: -100%;
    right: 0;
    bottom: 100%;
    left: 0;
    z-index: @zindex-account-takeover;
    outline: 0;
    color: @account-switcher-text;
    padding: 20px;

    .org-message {
      color:   @gray-150;
      display: inline-block;
      padding: 4px 0 6px;
    }

    form.search {
      .search-input {
        width: 100%;

        input {
          width: 100%;
          font-size: 16px;
          background: @account-switcher-bg;
          border-color: @gray-400;
          color: @gray-200;

          &::-ms-clear {
            display: none;
          }
        }
      }
    }

    .new-list-view-header {
      border-top: none;
      clear: both;
      background-color: transparent;
      border-bottom: 2px solid @account-switcher-border-color;

      h3 {
        color: @account-switcher-header-text;
      }
    }

    .col-alias {
      width: 105px;
    }

    .col-dc {
      width: 150px;
      display: none;
    }

    .col-active-item {
      width: 29px;
      padding: 0 0 0 15px;

      svg {
        margin-bottom: 2px;
        opacity: 0;
        transition: e('opacity .05s ease-in');
        fill: #FFF;
      }
    }

    .scroll-area {
      box-shadow: inset 0 2px 5px 0 fade(#000, 15);
      position: absolute;
      top: 130px;
      bottom: 20px;
      right: 20px;
      left: 20px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      .loading-list-view-items {
        background: @account-switcher-bg;
        border-bottom-color: @account-switcher-border-color;
        color: @gray-200;
      }

      .list-view-item {
        border-bottom-color: @gray-500;
        transition: e('color .05s ease-in');
        cursor: pointer;
        color:      @gray-150;
        background: @account-switcher-bg;

        &.current {
          background-color: @account-switcher-active-bg;
          color: #FFF;
        }

        &.active-item {
          background-color: @account-switcher-active-bg;
          color: #FFF;

          .col-active-item svg {
            opacity: 1;
          }

          &:hover {
            background-color: @account-switcher-active-bg;
            color: #FFF;
          }
        }

        .marker {
          display: inline-block;
          margin-right: 5px;
          color: darken(@account-switcher-text, 15%);
        }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  account-switcher {
    .take-over {
      form.search {
        float: right;

        .search-input {
          width: auto;

          input {
            width: auto;
          }
        }
      }

      .scroll-area {
        top: 100px;
      }
    }
  }
}

@media (min-width: @screen-xs-min) {
  account-switcher .header .current {
    max-width: none;
  }
}

@media (min-width: @screen-sm-min) {
  account-switcher {
    .header .current {
      max-width: 700px;
    }

    .take-over .col-dc {
      display: block;
    }
  }
}

@media (min-width: @screen-md-min) {
  account-switcher .header .current {
    max-width: 924px;
  }
}
