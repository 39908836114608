//
// Pagination
// --------------------------------------------------

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: @line-height-computed;
  margin-bottom: 0;
  border-radius: @border-radius-base;

  > li {
    display: inline; // Remove list-style and block-level defaults

    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: @pagination-padding-y @pagination-padding-x;
      margin-left: -1px;
      line-height: @line-height-base;
      color: @pagination-color;
      text-decoration: none;
      background-color: @pagination-bg;
      border: @pagination-border-width solid @pagination-border-color;
			font-weight: @font-weight-regular;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;
      }
    }
    &:last-child {
      > a,
      > span {
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover {
      background-color: @pagination-hover-bg;
    }
  }

  > .active > a,
  > .active > span {
		cursor: default;
		background-color: @pagination-active-bg;
		font-weight: @font-weight-bold;

    &:hover {
      background-color: @pagination-active-bg;  
    }
  }

  > .disabled {
    > span,
    > a {
      color: @text-color;
      background-color: @pagination-bg;
      border-color: @gray-150;
      opacity: 0.5;
			cursor: @cursor-disabled;
    }
  }
}
