body {
  background-color: @body-bg;
}

hr {
  border-top: 1px solid @border-color;
  margin: 1em 0;
  border-left: none;
  border-right: none;
}

.primary {
  border-bottom: 5px solid @border-color;
  margin-bottom: 50px;
  padding: 20px 0 60px;
  background: @primary-body-bg;
}

/* Display content for screen readers only
/* http://a11yproject.com/posts/how-to-hide-content */
 /*https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html*/

.sr-only {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
