.b-all {
  border-style: solid;
  border-width: @border-width-1;
  border-color: @border-color;
}

.b-top {
  border-top-style: solid;
  border-top-width: @border-width-1;
  border-top-color: @border-color;
}

.b-right {
  border-right-style: solid;
  border-right-width: @border-width-1;
  border-right-color: @border-color;
}

.b-bottom {
  border-bottom-style: solid;
  border-bottom-width: @border-width-1;
  border-bottom-color: @border-color;
}

.b-left {
  border-left-style: solid;
  border-left-width: @border-width-1;
  border-left-color: @border-color;
}

.b-none {
  border: none !important;
  border-width: 0;
}

@media (min-width: @screen-sm-min) {
  .b-all-sm {
    border-style: solid;
    border-width: @border-width-1;
    border-color: @border-color;
  }
  .b-top-sm {
    border-top-style: solid;
    border-top-width: @border-width-1;
    border-top-color: @border-color;
  }
  .b-right-sm {
    border-right-style: solid;
    border-right-width: @border-width-1;
    border-right-color: @border-color;
  }
  .b-bottom-sm {
    border-bottom-style: solid;
    border-bottom-width: @border-width-1;
    border-bottom-color: @border-color;
  }
  .b-left-sm {
    border-left-style: solid;
    border-left-width: @border-width-1;
    border-left-color: @border-color;
  }
}

@media (min-width: @screen-md-min) {
  .b-all-md {
    border-style: solid;
    border-width: @border-width-1;
    border-color: @border-color;
  }
  .b-top-md {
    border-top-style: solid;
    border-top-width: @border-width-1;
    border-top-color: @border-color;
  }
  .b-right-md {
    border-right-style: solid;
    border-right-width: @border-width-1;
    border-right-color: @border-color;
  }
  .b-bottom-md {
    border-bottom-style: solid;
    border-bottom-width: @border-width-1;
    border-bottom-color: @border-color;
  }
  .b-left-md {
    border-left-style: solid;
    border-left-width: @border-width-1;
    border-left-color: @border-color;
  }
}

@media (min-width: @screen-lg-min) {
  .b-all-lg {
    border-style: solid;
    border-width: @border-width-1;
    border-color: @border-color;
  }
  .b-top-lg {
    border-top-style: solid;
    border-top-width: @border-width-1;
    border-top-color: @border-color;
  }
  .b-right-lg {
    border-right-style: solid;
    border-right-width: @border-width-1;
    border-right-color: @border-color;
  }
  .b-bottom-lg {
    border-bottom-style: solid;
    border-bottom-width: @border-width-1;
    border-bottom-color: @border-color;
  }
  .b-left-lg {
    border-left-style: solid;
    border-left-width: @border-width-1;
    border-left-color: @border-color;
  }
}

@media (min-width: @screen-xl-min) {
  .b-all-xl {
    border-style: solid;
    border-width: @border-width-1;
    border-color: @border-color;
  }
  .b-top-xl {
    border-top-style: solid;
    border-top-width: @border-width-1;
    border-top-color: @border-color;
  }
  .b-right-xl {
    border-right-style: solid;
    border-right-width: @border-width-1;
    border-right-color: @border-color;
  }
  .b-bottom-xl {
    border-bottom-style: solid;
    border-bottom-width: @border-width-1;
    border-bottom-color: @border-color;
  }
  .b-left-xl {
    border-left-style: solid;
    border-left-width: @border-width-1;
    border-left-color: @border-color;
  }
}


.b-color-100      { border-color: @gray-100 }
.b-color-200      { border-color: @gray-200 }
.b-color-300      { border-color: @gray-200 }
.b-color-400      { border-color: @gray-400 }
.b-color-500      { border-color: @gray-400 }
.b-color-600      { border-color: @gray-400 }
.b-color-700      { border-color: @gray-500 }
.b-color-800      { border-color: @gray-500 }
.b-color-900      { border-color: @gray-500 }

.b-color-success  { border-color: @state-success-border }
.b-color-info     { border-color: @state-info-border }
.b-color-warning  { border-color: @state-warning-border }
.b-color-danger   { border-color: @state-danger-border }
