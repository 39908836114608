//
// Activity History
// --------------------------------------------------


.activity-history {

  ol {
    list-style: none;
    padding-left: 0;
  }

  li {
    border-top: 1px solid @gray-75;
    padding: @line-height-computed 0 @line-height-computed 0;

    &:first-of-type {
      border: none;
      padding-top: 0;
    }
  }

  .day {
    margin-bottom: (@line-height-computed / 2);

    h5.content-header {
      border-width: 1px;
    }
  }

  .details {
    margin:0;
  }

  .timestamp {
    float: none;
    font-size: @font-size-small;
  }

  .subject {
    color: @heading-text-color;
  }

  .body {
    font-size: @font-size-small;
  }

  .attribution {
    font-size: @font-size-small;
    margin-top: 7px;
  }

  .no-activity-message {
    font-size: @font-size-small;
    margin-top: 20px;
  }

  @media (min-width: @screen-sm-min) {

    .details {
      margin-left: 95px;
    }

    .timestamp {
      float: left;
      font-size: @font-size-regular;
    }
  }
}
