input.copyable {
  display: inline-block;
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  + button {
    background-color: #FFF;
    border: 1px solid @gray-150;
    border-left-width: 0;
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
    font-size: smaller;
    font-weight: bold;
    height: @input-height-base;
    line-height: 100%;
    padding: 0 10px;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

    svg {
      transition: fill 0.15s ease-in-out;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 2px fade(#000, 10);
      color: @gray-400;
      svg {
        fill: @gray-400;
      }
    }

    &:active {
      border: 1px solid fade(#000, 15);
      border-left-width: 0;
      background: @gray-75;
      box-shadow: inset 0 1px 2px fade(#000, 8);
      color: @gray-400;
      svg {
        fill: @gray-400;
      }
    }

    &[disabled],
    &.disabled {
      opacity: 0.65;
      cursor: @cursor-disabled;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }
}
