//
// Type utilities
//

// single line
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Transformation

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}


// Colors

.text-success,
.text-green {
  color: @state-success-text;
}

.text-warning,
.text-warning {
  color: @state-warning-text;
}

.text-danger,
.text-danger {
  color: @state-danger-text;
}

.deemphasize {
  color: #AAA;
}

.monospace {
  font-family: @mono-font-family;
}

// Alignment

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left,
.text-xs-left {
  text-align: left !important;
}
.text-right,
.text-xs-right {
  text-align: right !important;
}

.text-center,
.text-xs-center {
  text-align: center !important;
}

// Responsive Alignment

@media (min-width: @screen-xs) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: @screen-sm) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: @screen-md) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: @screen-lg) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
