
.process {
	position: relative;
}
.process-list {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin: auto;
	padding: 0;
	list-style: none;

	a {
		text-decoration: none;
	}

	li {
		flex: 2 0 auto;
		text-align: center;
		width: 0;

		&:first-child {
			text-align: left;
			flex-grow: 1;
		}

		&:last-child {
			flex-grow: 1;
			text-align: right;
		}

		&:hover {
			.process-step {
				background-color: darken(@process-step-bg, 30%);
			}
		}

		&.is-done {

			.process-step {
				background-color: @process-step-done-bg;
			}

			&:hover {
				.process-step {
					background-color: darken(@process-step-done-bg, 10%);
				}
			}
		}

		&.active {
			.process-step {
				background-color: @process-step-active-bg;
				border: 3px solid darken(@process-step-active-bg, 10%);
			}
		}

		&.active:hover {
			.process-step {
				background-color: darken(@process-step-active-bg, 10%);
			}
		}
	}
}

.process-step {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: @process-step-bg;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	transition: e('background-color .2s');
}

.process-label {
	display: block;
	margin-top: 7px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: @font-size-small;
}

.process-progress {
	position: absolute;
	top: 10px;
	left: 0px;
	display: block;
	width: 100%;
	height: .25rem;
	background-color: @process-step-bg;
}

.process-progress-bar {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: @process-step-done-bg;
	transition: e('background-color .2s');
}
