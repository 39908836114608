
//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: (@line-height-computed / 2);
  margin-bottom: -(@line-height-computed);
  list-style: none;

  > li {
    display: inline-block;
    padding: 8px 0;
    font-size: @font-size-small + 1;
    letter-spacing: 1px;

    + li:before {
      content: "|\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @gray-150;
    }

    &:last-child:after {
        content: "|\00a0"; // Unicode space added since inline-block means non-collapsing white-space
        padding: 0 5px;
        color: @gray-150;
    }
  }

  > .active {
    color: @gray-200;
  }

  @media (min-width: @screen-xs-min) {
  }
}
