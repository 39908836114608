//
// Clearfix
//

.clear-fix {
  *zoom: 1;

  &:before,
  &:after {
    display: table;
    content: '';
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}
