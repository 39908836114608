// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

.clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}
// Creates a wrapper for a series of columns

.make-row(@gutter: 40px) {
  margin-left: ceil((@gutter / -2));
  margin-right: floor((@gutter / -2));
  .clearfix();
}

// input text placeholder {

.placeholder (@color) {
  &::-webkit-input-placeholder {
    color: @color;
  }

  &:-moz-placeholder {
    color: @color;
  };

  &:-ms-input-placeholder {
    color: @color;
  }
}
// Resize anything

.resizable(@direction) {
  resize: @direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}
// CSS3 Content Columns

.content-columns(@columnCount, @columnGap: @gridGutterWidth) {
  column-count: @columnCount;
  column-gap: @columnGap;
}
// Optional hyphenation

.hyphens(@mode: auto) {
  word-wrap: break-word;
  hyphens: @mode;
}

.reset-text() {
  font-family: @font-family;
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: @line-height-base;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
// Grid System
// Creates a wrapper for a series of columns

.make-row(@gutter: @grid-gutter-width) {
  // Then clear the floated columns
  .clearfix();

  @media (min-width: @screen-sm-min) {
    margin-left: (@gutter / -2);
    margin-right: (@gutter / -2);
  }
  // Negative margin nested rows out to align the content of columns
  .row {
    margin-left: (@gutter / -2);
    margin-right: (@gutter / -2);
  }
}
// Generate the extra small columns

.make-xs-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: (@gutter / 2);
  padding-right: (@gutter / 2);
  // Calculate width based on number of columns available
  @media (min-width: @grid-float-breakpoint) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
// Generate the small columns

.make-sm-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: (@gutter / 2);
  padding-right: (@gutter / 2);
  // Calculate width based on number of columns available
  @media (min-width: @screen-sm-min) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
// Generate the small column offsets

.make-sm-column-offset(@columns) {
  @media (min-width: @screen-sm-min) {
    margin-left: percentage((@columns / @grid-columns));
  }
}

.make-sm-column-push(@columns) {
  @media (min-width: @screen-sm-min) {
    left: percentage((@columns / @grid-columns));
  }
}

.make-sm-column-pull(@columns) {
  @media (min-width: @screen-sm-min) {
    right: percentage((@columns / @grid-columns));
  }
}
// Generate the medium columns

.make-md-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: (@gutter / 2);
  padding-right: (@gutter / 2);
  // Calculate width based on number of columns available
  @media (min-width: @screen-md-min) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
// Generate the medium column offsets

.make-md-column-offset(@columns) {
  @media (min-width: @screen-md-min) {
    margin-left: percentage((@columns / @grid-columns));
  }
}

.make-md-column-push(@columns) {
  @media (min-width: @screen-md-min) {
    left: percentage((@columns / @grid-columns));
  }
}

.make-md-column-pull(@columns) {
  @media (min-width: @screen-md-min) {
    right: percentage((@columns / @grid-columns));
  }
}
// Generate the large columns

.make-lg-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: (@gutter / 2);
  padding-right: (@gutter / 2);
  // Calculate width based on number of columns available
  @media (min-width: @screen-lg-min) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
// Generate the large column offsets

.make-lg-column-offset(@columns) {
  @media (min-width: @screen-lg-min) {
    margin-left: percentage((@columns / @grid-columns));
  }
}

.make-lg-column-push(@columns) {
  @media (min-width: @screen-lg-min) {
    left: percentage((@columns / @grid-columns));
  }
}

.make-lg-column-pull(@columns) {
  @media (min-width: @screen-lg-min) {
    right: percentage((@columns / @grid-columns));
  }
}
// Alerts

.alert-variant(@background; @border; @icon-color; @text-color) {
  background-color: @background;
  border-color: @border;
  color: @text-color;

  > .cyclops-icon,
  .alert-icon .cyclops-icon {
    fill: @icon-color;
  }

  hr {
    border-top-color: darken(@border, 5%);
  }

  .alert-link {
    color: darken(@text-color, 10%);
  }
}

// Tags

.tag-variant(@color) {
  background-color: @color;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken(@color, 10%);
    }
  }
}

// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

.nav-divider(@color: @gray-75) {
  height: 1px;
  margin: ((@line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: @color;
}
// Navbar vertical align
//
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.

.navbar-vertical-align(@element-height) {
  margin-top: ((@navbar-height - @element-height) / 2);
  margin-bottom: ((@navbar-height - @element-height) / 2);
}

.button-variant-default(@color, @background, @border) {
  @active-background: @gray-75;
  @active-box-shadow: inset 0 1px 1px 0 fade(#000, 7);

  color: @color;
  background-color: @background;
  border-color: @border;
  box-shadow: 0 1px 1px 0 fade(#000, 4);

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: @color;
    background-color: @active-background;
    box-shadow: @active-box-shadow;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: @background;
      border-color: @border;
    }
    &:hover {
      background-color: @background;
      border-color: @border;
    }
  }

  &.btn-icon {
    background-color: transparent;
    border-color: transparent;
    .cyclops-icon {
      fill: @gray-200;
    }

    &:active,
    &.active {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:hover {
      .cyclops-icon {
        fill: @gray-500;
      }
    }
  }
}


.button-variant(@color, @background, @border) {
  @active-background: darken(@background, 3%);

  color: @color;
  background-color: @background;
  border-color: @border;

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: @active-background;
  }

  &.disabled,
  &:disabled {
    &:hover {
      background-color: @background;
      border-color: @border;
    }
  }

  &.btn-icon {
    background-color: transparent;
    border-color: transparent;
    .cyclops-icon {
      fill: @background;
    }

    &:active,
    &.active {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:hover {
      .cyclops-icon {
        fill: @active-background;
      }
    }

    &.disabled,
    &:disabled {
      &:hover {
        .cyclops-icon {
          fill: @background;
        }
      }
    }
  }

  .cyclops-icon {
    fill: @color;
  }
}
