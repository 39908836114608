.inline-confirm {
  overflow: hidden;
  
  .viewport {
    position: relative;
  }

  .original-button {
    margin-bottom: 5px;
  }

  .confirmation-container {
    clear: both;
    
    .btn.no {
      margin-right: 3px;
    }
  }

  .message {
    margin-right: 5px;
  }

  &.inline-confirm-primary {
    button.yes {
      .button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-bg);

      &:hover {
        background: #00308A;
        box-shadow: 0 1px 2px fade(#000, 15);
      }

      &:active {
        background: #001E60;
        border: 1px solid fade(#000, 15);
        box-shadow: inset 0 1px 2px fade(#000, 10);
      }
    }
  }

  &.inline-confirm-danger {
    button.yes {
      .button-variant(@btn-danger-color, @btn-danger-bg, @btn-danger-bg);

      &:hover {
        background: #DC221A;
        box-shadow: 0 1px 2px fade(#000, 15);
      }

      &:active {
        background: #BC0000;
        border: 1px solid fade(#000, 15);
        box-shadow: inset 0 1px 2px fade(#000, 10);
      }
    }
  }


  &.inline-confirm-default {
    button.yes {
      .button-variant-default(@btn-default-color, @btn-default-bg, @btn-default-border);

      &:hover {
        box-shadow: 0 1px 2px fade(#000, 10);
      }

      &:active {
        border: 1px solid fade(#000, 15);
        background: @gray-75;
        box-shadow: inset 0 1px 2px fade(#000, 8);
      }
    }
  }
}
