body.signin {
  background: #262626;
  color: @gray-200;
  min-height: 700px;
  p {
    margin-bottom: 15px;
  }

  h1 {
    color: #FFF;
    margin-bottom: @line-height-computed;
    margin-top: 60px;
  }

  h2, h3, h4 {
    color: #FFF;
  }

  .nav-brand {
    width: 200px;
    margin: 75px 0 120px;
  }

  .truste {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .signin-container {
    max-width: 390px;
    background-color: #FFF;
    border-radius: @border-radius-base;
    padding: 30px;
    margin-bottom: 20px;
    a {
      color: @gray-200;
    }
    &.signin-container-large {
      max-width: 100%;
    }
  }
  .text-center {
    .signin-container, .nav-brand {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      }
    }

  .signin-support {
    border-top: 2px solid @border-color;
    font-size: @font-size-small;
    margin-top: 20px;
    padding-top: 8px;
  }
}
