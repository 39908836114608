//
// Action Toolbar
// --------------------------------------------------

.action-area {
  background: @action-toolbar-bg;
  height: 40px;
  line-height: 20px;
  padding: 0 20px;
  overflow: hidden;
  .clearfix();

  &.action-toolbar-confirm-shown {
    .overflow-menu {
      display: none;
    }
  }

  .overflow-menu {
    vertical-align: inherit;
    height: 40px;
    position: absolute;
    display: inline-block;
    white-space: nowrap;

    &:hover,
    &.open {
      a,
      button {
        width: 100%;
        text-align: left;
        font-size: 13px;
      }

     .dropdown-menu {
       li > a,
       li > button {
         padding: 5px 10px;
       }
     }

      svg {
        fill: @gray-200;
      }
    }

    .dropdown-menu {
      border: 0;
      margin-top: 0;
      border-radius: 0;
      background: @gray-75;

      > li {
        a,
        button {
          background: transparent;
            svg.cyclops-icon {
              fill: @gray-200;
            }
        }
      }
    }
  }
}

.action-toolbar,
.action-toolbar-left,
.action-toolbar-right {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: @font-size-regular - 1;

  > li {
    float: left;

    a,
    button {
      background: transparent;
      display: inline-block;
      padding: 8px 10px 12px;
      transition: e('color .15s ease-in, padding .15s ease-in, background-color .15s ease-in');
      text-decoration: none;
      border:none;
      outline:none;


      &:not(.disabled):not(:disabled) {
        &:hover {
         background-color: @action-toolbar-hover-bg;
         svg.cyclops-icon {
           fill: @gray-500;
         }
        }
      }

      &:active, &.active {
        background-color: @action-toolbar-active-bg;
        svg.cyclops-icon {
          fill: @gray-500;
        }
      }

      &.disabled,
      &:disabled {
        color: #B9B9B9;
        cursor: @cursor-disabled;

        svg.cyclops-icon {
          fill: #B9B9B9;
        }
      }
    }
  }
}

.action-toolbar-left {
  float: left;
  margin-right: 110px;
}

.action-toolbar-right {
  float: right;
}

.action-toolbar-confirm {
  clear: both;
  background-color: @action-toolbar-dropdown-bg;
  color: #FFF;
  height: 40px;
  margin-right: -20px;
  padding-right: 20px;
  margin-left: -20px;
  padding-left: 20px;

  svg {
    fill: #FFF;
  }

  .message {
    display: none;
    padding-right: 10px;
    line-height: 40px;
    word-break: break-all;
    word-wrap: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttons {
    button {
      height: 40px;
      border: none;
      padding-right: 20px;
      padding-left: 20px;
      background: none;

      &:active,
      &:focus,
      &:hover {
        outline: none;
      }

      &.yes {
        background-color: @btn-default-bg;

        &:hover {
          background-color: darken(@btn-default-bg, @btn-hover-darken-percentage);
        }
      }

      &.no {
        color: @gray-100;

        svg {
          fill: @gray-100;
        }

        &:hover {
          color: #FFF;
          background-color: darken(@action-toolbar-dropdown-bg, 10%);

          svg {
            fill: #FFF;
          }
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    .buttons {
      float: right;
    }

    .message {
      display: block;
    }
  }

  &.action-toolbar-confirm-primary {
    border-bottom: none;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    button {
      &.yes {
        background-color: @btn-primary-bg;

        &:hover {
          background-color: darken(@btn-primary-bg, @btn-hover-darken-percentage);
        }
      }
    }
  }

  &.action-toolbar-confirm-danger {
    button {
      &.yes {
        background-color: @btn-danger-bg;

        &:hover {
          background-color: darken(@btn-danger-bg, @btn-hover-darken-percentage);
        }
      }
    }
  }
}
