//TODO color varibles?

slider {
  display: block;
  position: relative;
  margin-bottom: 20px;
  user-select: none;
}

.form-control.slider-text-input {
  width: 75px;
  position: absolute;
  top: 1px; // move for border
}

.slider-container {
  position: relative;
  margin-left: 85px;
  padding-top: 5px;
}

.slider-track {
  background-color: @gray-75;
  background-image: linear-gradient(to bottom, @gray-75, fade(@gray-75,50));
  border: solid 1px @gray-175;
  border-radius: @border-radius-small;
  margin-right: 10px;

  .slider-min-bound-label,
  .slider-max-bound-label {
    line-height: 18px;
    position: absolute;
    z-index: @zindex-slider;
    color: #FFF;
  }

  .slider-min-bound-label {
    left: 5px;
  }

  .slider-max-bound-label {
    right: 15px;
  }
}

.slider-valid-track {
  // allow the handle to be dragged over all the way to the right
  margin-right: 10px;
  background-color: #5394F5;
  height: 20px;
  position: relative;
}

.slider-track-click {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-right: 10px;
  z-index: @zindex-slider-track-click;
}

.slider-tick-container {
  position: relative;
  margin-right: 10px;
  height: 5px;
  margin-top: 3px;

  .slider-tick-mark {
    position: absolute;
    border-left: solid 1px #BBB;
    height: 5px;
    top: 0;
  }
}

.slider-out-of-range {
  position: relative;
  margin-right: 10px;
  width: 100%;

  .slider-out-of-range-value {
    height: 20px;
    position: absolute;
    z-index: @zindex-slider;

    .slider-out-of-range-marker {
      height: 20px;
      border-left: solid 1px @gray-175;
      border-color: fade(#000, 25);
    }

    .slider-out-of-range-number {
      display: block;
      margin-left: -50%;
    }
  }
}

.slider-out-of-range-message {
  margin-top: 20px;
}

.slider-handle2 {
  cursor: pointer;
  width: 21px;
  height: 30px;
  margin-top: -5px;
  margin-left: -10px;
  margin-right: -11px;
  position: absolute;
  left: 0;
  z-index: @zindex-slider-handle2;

  .slider-tooltip {
    opacity: 1;
    width: 50px;
    top: -33px;
    left: -15px;
    display: none;

    .tooltip-inner {
      background-color: #FFF;
      color: #5E6165;
      border: solid 1px @gray-175;
      border-radius: @border-radius-small;
    }
  }

  .fill {
    fill: #FFF;
  }

  .border {
    fill: @gray-175;
  }
}

.slider-value-fill {
  // Move it over the top of the track border
  position: absolute;
  top: -1px;
  left: -1px;
  height: 22px;
  background-color: @brand-primary;
  border: solid 1px @brand-primary;
  border-radius: @border-radius-small;
}

.has-min-bound {
  .slider-min-bound-label {
    color: @gray-175;
  }

  .slider-value-fill {
    border-radius: 0;
  }
}

.has-max-bound {
  .slider-max-bound-label {
    color: @gray-175;
  }
}

.disabled {
  .slider-value-fill {
    background-color: #5394F5;
    border: none;
    height: 20px;
    top: 0;
  }

  .slider-handle2.ui-state-disabled {
    cursor: not-allowed;
    opacity: 1;

    .handle-fill {
      fill: @gray-75;
    }
  }
}
