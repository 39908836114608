html.cyclops {
  @import "variables.less";
  @import "mixins.less";
  @import "normalize.less";
  @import "reset.less";
  @import "base.less";
  @import "utilities.less";
  @import "grid.less";
  @import "type.less";
  @import "buttons.less";
  @import "button-group.less";
  @import "dropdown.less";
  @import "forms.less";
  @import "tables.less";
  @import "nav.less";
  @import "progressBar.less";
  @import "servicesMenu.less";

  @import "slider.less";
  @import "toggle.less";
  @import "picker.less";
  @import "dateTimePicker.less";

  @import "copyable.less";
  @import "inlineConfirm.less";
  @import "passwordStrength.less";
  @import "tooltip.less";
  @import "listview.less";
  @import "search.less";
  @import "alerts.less";
  @import "navbar.less";
  @import "accountSwitcher.less";
  @import "signin.less";
  @import "activityHistory.less";
  @import "actionToolbar.less";
  @import "billing.less";
  @import "breadcrumb.less";
  @import "cards.less";
  @import "icons.less";
  @import "lists.less";
  @import "gitStats.less";
  @import "pagination.less";
  @import "process.less";
  @import "pricingEstimate.less";
  @import "slideWell.less";
  @import "upcomingEvents.less";
  @import "charts.less";
  @import "mainNav.less";
  @import "pane.less";
  @import "tree.less";
  @import "ribbon.less";
  @import "tag.less";
}
