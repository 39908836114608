
.b-width-0 {
  border-width: @border-width-0;
}

.b-width-1 {
  border-width: @border-width-1;
}

.b-width-2 {
  border-width: @border-width-2;
}

.b-width-3 {
  border-width: @border-width-3;
}

@media (min-width: @screen-sm-min) {
  .b-width-0-sm {
    border-width: @border-width-0;
  }
  .b-width-1-sm {
    border-width: @border-width-1;
  }
  .b-width-2-sm {
    border-width: @border-width-2;
  }
  .b-width-3-sm {
    border-width: @border-width-3;
  }
}

@media (min-width: @screen-md-min) {
  .b-width-0-md {
    border-width: @border-width-0;
  }
  .b-width-1-md {
    border-width: @border-width-1;
  }
  .b-width-2-md {
    border-width: @border-width-2;
  }
  .b-width-3-smdm {
    border-width: @border-width-3;
  }
}

@media (min-width: @screen-lg-min) {
  .b-width-0-lg {
    border-width: @border-width-0;
  }
  .b-width-1-lg {
    border-width: @border-width-1;
  }
  .b-width-2-lg {
    border-width: @border-width-2;
  }
  .b-width-3-lg {
    border-width: @border-width-3;
  }
}

@media (min-width: @screen-xl-min) {
  .b-width-0-xl {
    border-width: @border-width-0;
  }
  .b-width-1-xl {
    border-width: @border-width-1;
  }
  .b-width-2-xl {
    border-width: @border-width-2;
  }
  .b-width-3-xl {
    border-width: @border-width-3;
  }
}
